.textArea {
  background-color: #f3f6f9;
  border: none !important;
  outline: none !important;
  padding: 10px 26px 10px 12px;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  background-color: #F3F6F9;
  font-size: 16px !important;
  resize: none;
  font: inherit;
}


.textArea:focus {
  border-radius: 4 !important;
  border-color: "#80bdff" !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.textArea::placeholder{
  opacity: 0.8;
}
