@import "../../../_assets/sass/components/variables.bootstrap";

.container {
  min-height: 100vh;
  color: black;
  display: flex;
}

.logo {
  width: 10.8125rem;
  margin: 2vh 3vw;
}

.stepNumber {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #f3f6f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  background-color: rgba(54, 151, 211, 0.271);
  color: #3798d3;
}

.desc {
  color: #b5b5c3;
}
.leftBar {
  background: #fff;
  display: flex;
  height: 100vh;
  // position: fixed;
  flex-direction: column;
}
.stepsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.steps {
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.step {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 960px) {
  .leftBar {
    position: static;
    height: auto;
    min-height: 11rem;
  }
  .logo {
    width: 6rem;
  }
  .container {
    min-height: unset;
    height: 30vh;
    min-height: 15rem;
  }
  .steps {
    flex-direction: row;
  }

  .step {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    h6 {
      font-size: 1rem;
    }
    p {
      font-size: 0.6rem;
    }
    h5 {
      font-size: 1.3rem;
    }
  }
  .stepNumber {
    width: 3rem;
    height: 3rem;
  }
}
@media (max-width: 400px) {
  .steps {
    flex-direction: row;
  }

  .step {
    h6 {
      font-size: 0.7rem;
    }
    p {
      font-size: 0.5rem;
    }
    h5 {
      font-size: 1rem;
    }
  }
  .stepNumber {
    width: 3rem;
    height: 3rem;
  }
}
.plane {
  width: 100%;
  // height: 10rem;
  max-height: 25.875rem;
}

.content {
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  padding: 2vh 5vw;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  // justify-content: space-between;
}

.issues {
  margin: 2rem 2rem 2rem auto;
  color: $text-muted;
}

.emailImg {
  width: 13.25rem;
  margin-bottom: 1rem;
}
