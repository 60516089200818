.input {
  border: 1px solid var(--input-bg-color);
  background-color: var(--input-bg-color);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 20px;
  outline: var(--primary-color);
  width: 100%;
  position: relative;
}
.inputSecondary {
  border: 1px solid #fff;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 20px;
  outline: var(--primary-color);
  width: 100%;
  position: relative;
}
.inputSecondary:disabled {
  background-color: #f2f2f2;
  color:#666666;
}
.inputOutlined {
  border: 1px solid var(--input-border-color) !important;
}
.inputError {
  border: 1px solid var(--danger-color) !important;
}
.input:hover {
  border: 1px solid var(--primary-hover-color);
}
.input:focus {
  border: 1px solid var(--primary-hover-color);
}
.input:focus {
  border: 1px solid var(--primary-hover-color);
}
.searchIcon {
  position: absolute;
  top: 5px;
  left: 15px;
  height: 100%;
  // display: flex;
  // align-items: center;
}
