.tab {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  color: white;
  height: 100%;
  border-left: 1px solid #fff;
}
.tab:first-child {
  border-top-left-radius: 0.475rem;
}
.tab:last-child {
  border-top-right-radius: 0.475rem;
}
.activeButton {
  background-color: white;
  color: #000000;
}
.cardSpecial {
  border-top-left-radius: unset !important;
}

// .cardContent {
//   min-height: 200px !important;
// }

.sub {
  color: #b5b5c3;
  font-size: 1rem;
}
.reverse {
  position: absolute;
  left: -20px;
  top: 15px;
}
.seperator {
  position: relative;
  height: 75px;
  width: 10px;
  border-left: 1px #e5eaee solid;
  // margin: 0 2rem;
}
.specialBorder {
  border-left: 5px dotted rgba(196, 196, 196, 0.56);
  padding-left: 10px;
  position: relative;
}
.specialBorder::before {
  content: "";
  border-radius: 50%;
  border: 2px solid rgba(196, 196, 196, 0.56);
  background-color: white;
  width: 15px;
  height: 15px;
  position: absolute;
  left: -10px;
}
.specialBorder::after {
  content: "";
  border-radius: 50%;
  border: 2px solid rgba(196, 196, 196, 0.56);
  background-color: white;
  width: 15px;
  height: 15px;
  position: absolute;
  left: -10px;
  bottom: 0;
}
.hide {
  height: 0;
  overflow: hidden;
  padding: 0 !important;
}
.show {
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  height: auto !important;
}
.inputWrapper {
  display: flex;
  gap: 0.625rem;
  flex-wrap: wrap;
}
.svg {
  path {
    fill: #ffffff;
  }
}
.svgActive {
  path {
    fill: #098cb6;
  }
}
.duration {
  border-top: 1px solid #b5b5c3;
  border-bottom: 1px solid #b5b5c3;
  padding: 1rem 0;
  margin: 1rem 0;
}
.SeatWrapper {
  width: 45px;
  height: 45px;
  max-width: 10vw;
  max-height: 10vw;
  background-color: #fe9252;
  border-radius: 0.75rem;
  border: none;
  padding: 0;
  margin-right: 7px;
}
.SeatWrapper_disabled {
  background-color: #d8d8d8;
}
.SeatWrapper_selected {
  background-color: #3798d3;
}
.passenger {
  padding: 6px 6px 6px 0;
  border: 1px solid #b5b5c3;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  span {
    font-size: 14px;
  }
  span:first-of-type {
    margin: 0;
    border-left: 7px solid #3798d3;
    padding-left: 5px;
  }
}

.passenger:nth-of-type(n + 4) {
  span:first-of-type {
    margin: 0;
    border-left: 7px solid #ff4b55;
    padding-left: 5px;
  }
}
.label {
  color: #fff;
  background-color: #fe9252;
  border-radius: 4px;
  padding: 4px;
  font-weight: 500;
}
.qrCode {
  padding: 1em;
  border: 0.25em solid;
  position: relative;
  color: #3798d3;
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    background: #fff;
  }

  &::before {
    top: -0.3em; // let it overlap a bit to prevent thin line which could appear during scaling in some browsers etc.
    bottom: -0.3em;
    left: 1em;
    right: 1em;
  }

  &::after {
    left: -0.3em;
    right: -0.3em;
    top: 1em;
    bottom: 1em;
  }

  // you need this to move the text in front of the ::after overlay
  .heading {
    position: relative;
    z-index: 1;
  }
}

.datePickerInput {
  position: relative;
  width: 100%;
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}

.flightsCard {
  overflow-x: auto;
  max-width: 100%;
}

.percentageLabel {
  background-color: var(--input-bg-color);
  margin-left: 1rem;
  height: 100%;
  display: block;
  padding: 9px 8px;
}

.passengersButton {
  font-size: 16px !important;
  color: #000000;
  font-weight: 300 !important;
  background: unset;
  border: unset;
  margin-right: 13px;
  cursor: pointer;
}

.roundButton {
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: unset;
}

.roundButton:disabled {
  border: 1px solid var(--primary-disabled-color);
  color: var(--primary-disabled-color);
}
