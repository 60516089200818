.header {
  padding: 15px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  top: 0;
  position: sticky;
  // width: 100%;
  right: 0;
  left: 0;
  z-index: 999;
}

.headerVisible {
  /* display: flex; */
  top: 0;
  transition: top 0.4s ease-out;
}

.headerHidden {
  /* display: none; */
  top: -80px;
  transition: top 0.4s ease-out;
}

.footerList {
  list-style: none;
  a {
    color: black;
    font-weight: 400;
  }
}

.content {
  padding-top: 80px;
}

.div {
  background-color: #fff;
  display: flex;
  // padding-bottom: 50px;
  flex-direction: column;
}
.div-2 {
  justify-content: space-between;
  align-self: stretch;
  z-index: 1;
  width: 100%;
  padding: 33px 80px;
}
@media (max-width: 991px) {
  .div-2 {
    max-width: 100%;
    padding: 0 20px;
  }
}
.img {
  aspect-ratio: 3.44;
  object-fit: contain;
  object-position: center;
  width: 179px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
}
.div-5 {
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 5px;
  padding: 4px 25px 4px 10px;
}
@media (max-width: 991px) {
  .div-5 {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    padding-right: 20px;
  }
}
.homeNavLink {
  color: #000;
  letter-spacing: -0.2px;
  white-space: nowrap;
  justify-content: center;
  border-radius: 9px;
  align-self: stretch;
  flex-grow: 1;
  padding: 15px;
  font: 600 16px/16px Inter, -apple-system, Roboto, Helvetica, sans-serif;
  &:hover {
    color: #f8f9fa;
    background-color: #2a7bb9;
  }
  &:active {
    color: #f8f9fa;
    background-color: #1e5a8a;
  }
}
.homeNavLink_active {
  color: #f8f9fa;
  background-color: #2a7bb9;
}
@media (max-width: 991px) {
  .homeNavLink {
    white-space: initial;
  }
}
.div-7 {
  color: #000;
  letter-spacing: -0.2px;
  align-self: center;
  margin: auto 0;
  font: 600 20px/20px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-8 {
  color: #000;
  letter-spacing: -0.2px;
  align-self: center;
  margin: auto 0;
  font: 600 20px/20px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-9 {
  color: #000;
  letter-spacing: -0.2px;
  margin: auto 0;
  font: 600 20px/20px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-10 {
  color: #000;
  letter-spacing: -0.2px;
  align-self: center;
  margin: auto 0;
  font: 600 20px/20px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-11 {
  color: #000;
  letter-spacing: -0.2px;
  align-self: center;
  white-space: nowrap;
  margin: auto 0;
  font: 600 20px/20px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-11 {
    white-space: initial;
  }
}
.div-12 {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: auto 0;
}
@media (max-width: 991px) {
  .div-12 {
    margin-top: 40px;
  }
}
.div-13 {
  color: var(--white-dark-white, #fff);
  white-space: nowrap;
  border-radius: 6px;
  background-color: #3798d4;
  flex-grow: 1;
  justify-content: center;
  padding: 16px 24px;
  font: 600 16px/16px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-13 {
    white-space: initial;
    padding: 0 20px;
  }
}
.div-14 {
  color: #3798d4;
  white-space: nowrap;
  border-radius: 6px;
  border: 1px solid #3798d4;
  flex-grow: 1;
  justify-content: center;
  padding: 16px 24px;
  font: 600 16px/16px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-14 {
    white-space: initial;
    padding: 0 20px;
  }
}
.div-15 {
  align-self: center;
  margin-top: -124px;
  width: 100%;
  max-width: 1392px;
}
@media (max-width: 991px) {
  .div-15 {
    max-width: 100%;
  }
}
.div-16 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-16 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column-3 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 75%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-3 {
    width: 100%;
  }
}
.div-17 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-17 {
    max-width: 100%;
    margin-top: 19px;
  }
}
.img-2 {
  aspect-ratio: 1.11;
  object-fit: contain;
  object-position: center;
  width: 351px;
  overflow: hidden;
  align-self: end;
  max-width: 100%;
}
.div-18 {
  margin-top: 9px;
}
@media (max-width: 991px) {
  .div-18 {
    max-width: 100%;
  }
}
.div-19 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-19 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column-4 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 64%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-4 {
    width: 100%;
  }
}
.div-20 {
  align-items: start;
  display: flex;
  flex-direction: column;
  padding: 12px 20px 0;
}
@media (max-width: 991px) {
  .div-20 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-21 {
  color: #000;
  align-self: stretch;
  font: 700 59px Inter, sans-serif;
}
@media (max-width: 991px) {
  .div-21 {
    max-width: 100%;
    font-size: 40px;
  }
}
.div-22 {
  color: var(--gray-gray-600, #7e8299);
  text-align: justify;
  align-self: stretch;
  margin-top: 35px;
  font: 500 20px/30px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-22 {
    max-width: 100%;
  }
}
.div-23 {
  display: flex;
  margin-top: 30px;
  gap: 10px;
}
.div-24 {
  color: var(--white-dark-white, #fff);
  white-space: nowrap;
  border-radius: 6px;
  background-color: #3798d4;
  flex-grow: 1;
  justify-content: center;
  padding: 16px 24px;
  font: 600 16px/16px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-24 {
    white-space: initial;
    padding: 0 20px;
  }
}
.div-25 {
  color: #3798d4;
  white-space: nowrap;
  border-radius: 6px;
  border: 2px solid #3798d4;
  z-index: 1;
  flex-grow: 1;
  justify-content: center;
  padding: 16px 24px;
  font: 600 16px/16px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-25 {
    white-space: initial;
    padding: 0 20px;
  }
}
.column-5 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 36%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-5 {
    width: 100%;
  }
}
.img-3 {
  aspect-ratio: 0.83;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
  flex-grow: 1;
}
@media (max-width: 991px) {
  .img-3 {
    margin-top: 40px;
  }
}
.img-4 {
  aspect-ratio: 0.83;
  object-fit: contain;
  object-position: center;
  width: 351px;
  overflow: hidden;
  align-self: end;
  margin-top: 19px;
  max-width: 100%;
}
.column-6 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-6 {
    width: 100%;
  }
}
.div-26 {
  display: flex;
  margin-top: 77px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-26 {
    margin-top: 40px;
  }
}
.img-5 {
  aspect-ratio: 0.83;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
}
.img-6 {
  aspect-ratio: 0.83;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  margin-top: 19px;
}
.div-27 {
  justify-content: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 50px 60px;
}
@media (max-width: 991px) {
  .div-27 {
    max-width: 100%;
    padding: 0 20px;
  }
}
.div-28 {
  width: 100%;
  max-width: 1326px;
  margin: 32px 0;
}
@media (max-width: 991px) {
  .div-28 {
    max-width: 100%;
  }
}
.div-29 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-29 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column-7 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 52%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-7 {
    width: 100%;
  }
}
.img-7 {
  aspect-ratio: 0.93;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
}
@media (max-width: 991px) {
  .img-7 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.column-8 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 48%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-8 {
    width: 100%;
  }
}
.div-30 {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 30px;
  flex-direction: column;
  margin: auto 0;
}
@media (max-width: 991px) {
  .div-30 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-31 {
  color: #000;
  text-transform: capitalize;
  align-self: stretch;
  font: 700 50px Poppins, sans-serif;
}
@media (max-width: 991px) {
  .div-31 {
    max-width: 100%;
    font-size: 40px;
  }
}
.div-32 {
  border-radius: 36px;

  align-self: stretch;
  display: flex;
  margin-top: 72px;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  padding: 32px 73px 32px 57px;
}
.div-32 {
  transition: transform 0.3s ease;
}

.div-32:hover {
  transform: scale(1.1);
  box-shadow: 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0),
    0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01),
    0px 20px 13px 0px rgba(0, 0, 0, 0.01),
    0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.01),
    0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02),
    0px 100px 80px 0px rgba(0, 0, 0, 0.02);
  background-color: var(--white, #fff);
}

@media (max-width: 991px) {
  .div-32 {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
    padding: 0 20px;
  }
}
.img-8 {
  aspect-ratio: 0.98;
  object-fit: contain;
  object-position: center;
  width: 47px;
  overflow: hidden;
  max-width: 100%;
}
.div-33 {
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}
.div-34 {
  color: #000;
  white-space: nowrap;
  font: 700 16px/20px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-34 {
    white-space: initial;
  }
}
.div-35 {
  color: #7e8299;
  margin-top: 11px;
  font: 400 16px/20px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-36 {
  display: flex;
  margin-top: 45px;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 991px) {
  .div-36 {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
}
.div-37 {
  display: flex;
  flex-basis: 0%;
  flex-direction: column;
  align-items: center;
}
.img-9 {
  aspect-ratio: 1.17;
  object-fit: contain;
  object-position: center;
  width: 56px;
  overflow: hidden;
}
.img-10 {
  aspect-ratio: 1.17;
  object-fit: contain;
  object-position: center;
  width: 56px;
  overflow: hidden;
  margin-top: 57px;
}
@media (max-width: 991px) {
  .img-10 {
    margin-top: 40px;
  }
}
.div-38 {
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
}
.div-39 {
  color: #000;
  white-space: nowrap;
  font: 700 16px/20px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-39 {
    white-space: initial;
  }
}
.div-40 {
  color: #7e8299;
  margin-top: 7px;
  font: 400 16px/20px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-41 {
  color: #000;
  margin-top: 44px;
  white-space: nowrap;
  font: 700 16px/20px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-41 {
    margin-top: 40px;
    white-space: initial;
  }
}
.div-42 {
  color: #7e8299;
  margin-top: 8px;
  font: 400 16px/20px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-43 {
  justify-content: center;
  background-color: #fff;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-43 {
    max-width: 100%;
  }
}
.div-44 {
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 692px;
  width: 100%;
  justify-content: center;
  padding: 0 1px;
}
@media (max-width: 991px) {
  .div-44 {
    max-width: 100%;
  }
}
.img-11 {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.div-45 {
  position: relative;
  background-color: rgba(0, 0, 0, 0.61);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
}
@media (max-width: 991px) {
  .div-45 {
    max-width: 100%;
  }
}
.div-46 {
  color: #fff;
  text-align: center;
  max-width: 984px;
  font: 700 48px Poppins, sans-serif;
}
@media (max-width: 991px) {
  .div-46 {
    max-width: 100%;
    font-size: 40px;
  }
}
.div-47 {
  justify-content: space-between;
  align-self: stretch;
  margin: 23px 0 94px;
  padding: 0 80px;
}
@media (max-width: 991px) {
  .div-47 {
    max-width: 100%;
    margin-bottom: 40px;
    padding: 0 20px;
  }
}
.div-48 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-48 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column-9 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-9 {
    width: 100%;
  }
}
.div-49 {
  justify-content: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 7px 0;
}
@media (max-width: 991px) {
  .div-49 {
    margin-top: 39px;
  }
}
.img-12 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 171px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
}
.div-50 {
  color: #fff;
  text-align: center;
  margin-top: 15px;
  font: 600 20px Poppins, sans-serif;
}
.div-51 {
  color: #fff;
  text-align: center;
  margin-top: 15px;
  font: 300 16px Poppins, sans-serif;
}
.column-10 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-10 {
    width: 100%;
  }
}
.div-52 {
  justify-content: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 7px 0;
}
@media (max-width: 991px) {
  .div-52 {
    margin-top: 39px;
  }
}
.div-53 {
  border-radius: 50%;
  align-self: center;
  display: flex;
  width: 171px;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
}
.img-13 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  border-radius: 50%;
}
.div-54 {
  color: #fff;
  text-align: center;
  margin-top: 15px;
  font: 600 20px Poppins, sans-serif;
}
.div-55 {
  color: #fff;
  text-align: center;
  margin-top: 15px;
  font: 300 16px Poppins, sans-serif;
}
.column-11 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-11 {
    width: 100%;
  }
}
.div-56 {
  justify-content: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 20px 0;
}
@media (max-width: 991px) {
  .div-56 {
    margin-top: 39px;
  }
}
.img-14 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 169px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
}
.div-57 {
  color: #fff;
  text-align: center;
  margin-top: 15px;
  font: 600 20px Poppins, sans-serif;
}
.div-58 {
  color: #fff;
  text-align: center;
  margin-top: 15px;
  white-space: nowrap;
  font: 300 16px Poppins, sans-serif;
}
@media (max-width: 991px) {
  .div-58 {
    white-space: initial;
  }
}
.column-12 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-12 {
    width: 100%;
  }
}
.div-59 {
  justify-content: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 8px 0;
}
@media (max-width: 991px) {
  .div-59 {
    margin-top: 39px;
  }
}
.div-60 {
  border-radius: 50%;
  align-self: center;
  display: flex;
  width: 169px;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.img-15 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  border-radius: 50%;
}
.div-61 {
  color: #fff;
  text-align: center;
  margin-top: 15px;
  font: 600 20px Poppins, sans-serif;
}
.div-62 {
  color: #fff;
  text-align: center;
  margin-top: 15px;
  font: 300 16px Poppins, sans-serif;
}
.div-63 {
  background-color: #f8f9fa;
  align-self: stretch;
  z-index: 1;
  display: flex;
  margin-top: 6px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 9px 60px 50px;
}
@media (max-width: 991px) {
  .div-63 {
    max-width: 100%;
    padding: 0 20px;
  }
}
.div-64 {
  margin-bottom: 14px;
  width: 100%;
  max-width: 1393px;
}
@media (max-width: 991px) {
  .div-64 {
    max-width: 100%;
  }
}
.div-65 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-65 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column-13 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 62%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-13 {
    width: 100%;
  }
}
.div-66 {
  display: flex;
  margin-top: 124px;
  flex-grow: 1;
  flex-direction: column;
}
@media (max-width: 991px) {
  .div-66 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-67 {
  color: #000;
  font: 600 59px Poppins, sans-serif;
}
@media (max-width: 991px) {
  .div-67 {
    max-width: 100%;
    font-size: 40px;
  }
}
.img-16 {
  aspect-ratio: 1.66;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  margin-top: 67px;
}
@media (max-width: 991px) {
  .img-16 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.column-14 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 38%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-14 {
    width: 100%;
  }
}
.img-17 {
  aspect-ratio: 0.52;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 991px) {
  .img-17 {
    max-width: 100%;
  }
}
.div-68 {
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  align-self: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 50px 60px;
}
@media (max-width: 991px) {
  .div-68 {
    max-width: 100%;
    padding: 0 20px;
  }
}
.div-69 {
  width: 922px;
  max-width: 100%;
  margin: 7px 0;
}
.div-70 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-70 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column-15 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 73%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column-15 {
    width: 100%;
  }
}
.div-71 {
  display: flex;
  flex-direction: column;
  margin: auto 0;
}
@media (max-width: 991px) {
  .div-71 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-72 {
  justify-content: center;
  color: var(--text-color-dark, #252f40);
  letter-spacing: -0.8px;
  font: 600 24px Poppins, sans-serif;
}
@media (max-width: 991px) {
  .div-72 {
    max-width: 100%;
  }
}
.div-73 {
  justify-content: center;
  color: var(--text-color-slate-gray, #67748e);
  margin-top: 23px;
  font: 400 16px/26px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-73 {
    max-width: 100%;
  }
}
.div-74 {
  display: flex;
  margin-top: 28px;
  justify-content: space-between;
  gap: 13px;
}
@media (max-width: 991px) {
  .div-74 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.div-75 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  justify-content: center;
}
.div-76 {
  justify-content: center;
  color: var(--text-color-trout, #495057);
  max-width: 303px;
  border-radius: 8px;
  border: 1px solid #d2d6da;
  background-color: var(--color-white, #fff);
  align-items: start;
  padding: 15px 60px 15px 13px;
  font: 400 14px/7px Open Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-76 {
    padding-right: 20px;
  }
}
.div-77 {
  color: #fff;
  white-space: nowrap;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07),
    0px 4px 7px -1px rgba(0, 0, 0, 0.11);
  background-color: #3798d4;
  padding: 17px 29px;
  font: 500 14px/7px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-77 {
    white-space: initial;
    padding: 0 20px;
  }
}
.column-16 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 27%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-16 {
    width: 100%;
  }
}
.img-18 {
  aspect-ratio: 0.72;
  object-fit: contain;
  object-position: center;
  width: 224px;
  overflow: hidden;
  max-width: 100%;
  flex-grow: 1;
}
@media (max-width: 991px) {
  .img-18 {
    margin-top: 40px;
  }
}
.img-19 {
  aspect-ratio: 3.34;
  object-fit: contain;
  object-position: center;
  width: 194px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
}
.div-79 {
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  margin: auto 0;
}
.div-80 {
  color: var(--black, #080809);
  white-space: nowrap;
  font: 700 21px/26px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-80 {
    white-space: initial;
  }
}
.div-81 {
  color: #000;
  margin-top: 34px;
  white-space: nowrap;
  font: 500 18px/22px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-81 {
    white-space: initial;
  }
}
.div-82 {
  color: #000;
  margin-top: 10px;
  white-space: nowrap;
  font: 500 18px/22px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-82 {
    white-space: initial;
  }
}
.div-83 {
  color: #000;
  margin-top: 10px;
  white-space: nowrap;
  font: 500 18px/22px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-83 {
    white-space: initial;
  }
}
.img-20 {
  aspect-ratio: 3.93;
  object-fit: contain;
  object-position: center;
  width: 177px;
  overflow: hidden;
  max-width: 100%;
}
.div-85 {
  color: #000;
  letter-spacing: 0.1px;
  align-self: stretch;
  margin-top: 30px;
  white-space: nowrap;
  font: 500 20px/25px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-85 {
    white-space: initial;
  }
}
.div-86 {
  align-self: stretch;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  gap: 7px;
}
.img-21 {
  aspect-ratio: 3.06;
  object-fit: contain;
  object-position: center;
  width: 107px;
  overflow: hidden;
  max-width: 100%;
}
.img-22 {
  aspect-ratio: 2.86;
  object-fit: contain;
  object-position: center;
  width: 100px;
  overflow: hidden;
  max-width: 100%;
}
.div-87 {
  color: #000;
  text-align: center;
  margin-top: 74px;
  white-space: nowrap;
  font: 500 14px/17px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .div-87 {
    margin-top: 40px;
    white-space: initial;
  }
}
