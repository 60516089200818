.tabsTitles {
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
  // overflow: scroll;
}

.tabTitle {
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #3798d3;
  display: inline-block;
  padding: 10px 20px;
  color: #000000;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 500;
  white-space: nowrap;
}

.tabTitle_active {
  background-color: #3798d3;
  color: #fff;
}

.tabTitle_activeDanger {
  background-color: var(--danger-color);
  color: #fff;
  border: none;
}

.tabContent {
  padding: 5px;
  margin: 0;
  margin-top: 1rem;
}
