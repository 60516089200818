.tab {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  cursor: pointer;
  img {
    width: 3.875rem;
    margin-bottom: 2rem;
  }
  h6 {
    color: #b5b5c3;
    text-align: center;
  }
}

.activeTab {
  background: #ff4b55 !important;
  h6 {
    color: #ffffff;
  }
}

.paper {
  color: #464e5f !important;
  padding: 1.5rem;
}

.mapContainer {
  display: flex;
  img {
    margin-left: auto;
    width: 100%;
    height: auto;
    min-width: 18.75rem;
    max-width: 26.875rem;
  }
}
