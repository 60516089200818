.background {
  background: url(../../../assets/bg/helpCenter.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactUsDetails {
  background: url(../../../assets/bg/contactus.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  padding: 3rem;
}
.container {
  background-color: #f8fdff;
  padding: 4rem 5vw;
}
