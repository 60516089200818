.logo {
  width: 10.8125rem;
  margin: 2vh 3vw;
}

.signInWrapper{
  max-width: 28.125rem;
  margin: auto;
}

.links{
  list-style: none;
  display: flex;
  gap: 2rem;
  margin: 2rem auto;
}