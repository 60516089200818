.background {
  background: url(../../../../assets/bg/landing.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  // height: 60vh;
}

.section {
  padding: 2rem 10vw 0 10vw;
}

.topSection {
  width: 100%;
  height: 100%;
  min-height: 500px;
  background-color: rgba(9, 105, 136, 0.44);
  position: relative;
  display: flex;
  flex-direction: column;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.curve {
  width: 100vw;
  display: block;
}

.curveContainer {
  position: absolute;
  bottom: 90px;
  left: 0;
  right: 0;
}

.rectangle {
  width: 100vw;
  height: 100px;
  background: #fff;
  position: absolute;
  margin-top: -10px;
}

.arrow {
  color: #3798d3;
  box-shadow: -16px 8px 30px -12px rgba(0, 0, 0, 0.75);
  z-index: 10;
  display: block;
}

.list {
  list-style: none;
  a {
    color: black;
    font-weight: 400;
  }
}

.subscribe {
  // margin: 5rem 0;
  background-color: #3798d3;
  border-top: 30px solid rgba(224, 224, 224, 0.205);
  border-bottom: 30px solid rgba(224, 224, 224, 0.205);
  text-align: center;
  padding: 2rem 10vw;
  box-sizing: content-box;
}

.info {
  padding: 5rem 10vw;
  margin-top: 5rem;
  background-color: #f8f9fa;
}

.mapContainer {
  display: flex;
  img {
    width: 100%;
    height: auto;
    max-width: 90vh;
  }
}

@media (max-width: 768px) {
  .section {
    padding: 2rem 5vw 0 5vw;
  }
}

.imageContainer {
  display: flex;
  flex-direction: column;
  height: 200px; /* Adjust to fit two images */
  overflow: hidden;
  animation: scroll 6s linear infinite;
}

.scrollingImage {
  width: 100%;
  height: 100px; /* Adjust to fit two images */
}

@keyframes scroll {
  0% {
    top: 0;
  }
  100% {
    top: -100px; /* Adjust to the height of one image */
  }
}
